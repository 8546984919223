/* eslint-disable no-plusplus */
import { AppSyncFarm, AppSyncFarmToken } from 'common/models/monitorVaults';
import { Nullable } from 'common/models/optional';
import { FarmUserInfoToken } from 'common/models/token';
import { AppSyncUserInfo } from 'common/models/userInfo';
import { ICHI_TOKENS } from 'constants/constants';
import { NumberUtils } from 'utils/numberUtils';
import { ChainId } from '@ichidao/ichi-sdk/dist/src/crypto/networks';
import { BigNumber } from 'ethers';
const univ3prices = require('@thanpolas/univ3prices');

export namespace FarmUtils {

  export function nonIchiTokenAddress(token0: string, token1: string, chainId: number): string {
    if (ICHI_TOKENS[chainId].address.toLowerCase() === token0.toLocaleLowerCase()) {
      return token1;
    }

    return token0;
  }

  export function nonIchiToken(token0: AppSyncFarmToken, token1: AppSyncFarmToken, chainId: number): AppSyncFarmToken {
    if (ICHI_TOKENS[chainId].address.toLowerCase() === token0.address.toLocaleLowerCase()) {
      return token1;
    }

    return token0;
  }

  export function chooseOneToken(token0: AppSyncFarmToken, token1: AppSyncFarmToken, chainId: number): AppSyncFarmToken {
    if (token0.name.toLowerCase().startsWith('one') || token0.name.toLowerCase().startsWith('test_one')) {
      return token0;
    }

    return token1;
  }

  export function nonIchiTokenFromUserInfo(token0: FarmUserInfoToken, token1: FarmUserInfoToken, chainId: number): FarmUserInfoToken {
    if ((token0.address) && (ICHI_TOKENS[chainId].address.toLowerCase() === token0.address.toLocaleLowerCase())) {
      return token1;
    }

    return token0;
  }

  export function getAdjustedPoolId(poolId: number, chainId: number): number {
    if (poolId >= 1000) {
      if (chainId === ChainId.Mainnet) {
        return poolId - 1000;
      }
    }
    return poolId;
  }

  export function userIchiRewards(userInfo: AppSyncUserInfo, farm: AppSyncFarm): Nullable<number> {
    if (!userInfo || !userInfo.farms) {
      return null;
    }

    let decimals = 9;

    for (let i = 0; i < userInfo.farms.length; i++) {
      if (userInfo.farms[i].poolId === farm.poolId) {
        if (farm.isGeneric) {
          decimals = farm.farm.farmRewardTokenDecimals;
        }
        return NumberUtils.BNtoNumberWithoutDecimals(userInfo.farms[i].rewardLP, decimals);
      }
    }
    return 0;
  }

  export function userBalance(userInfo: AppSyncUserInfo, poolId: number) {
    if (!userInfo || !userInfo.farms) {
      return 0;
    }

    for (let i = 0; i < userInfo.farms.length; i++) {
      if (userInfo.farms[i].poolId === poolId) {
        return NumberUtils.BNtoNumberWithoutDecimals(userInfo.farms[i].userLP, 18);
      }
    }
    return 0;
  }

  export function getBaseToken(farm: Nullable<AppSyncFarm>): Nullable<AppSyncFarmToken> {
    if (!farm) {
      console.error('Could not get base token, no farm given!');
      return null;
    }
    return farm.tokens[0].isBaseToken ? farm.tokens[0] : farm.tokens[1];
  }

  export function getBaseTokenPrice(farm: Nullable<AppSyncFarm>): number {
    if (!farm) {
      console.error('Could not get base token, no farm given!');
      return 0;
    }

    const baseToken: AppSyncFarmToken = getBaseToken(farm);
    const price = baseToken.price;
    return price;
  }

  export function getPriceFromPool(decimals:[number, number], tick: number): Object {
    const price = univ3prices.tickPrice(decimals, tick);
    return price;
  }
  export function getPriceInDepositToken(isVaultInverted: boolean, decimals:[number, number], tick: number): number {
    const priceFromPool = getPriceFromPool(decimals, tick);
    const result = (isVaultInverted && tick > 0) || (!isVaultInverted && tick < 0) 
      ? Number(priceFromPool.toFixed()) 
      : Number(1/priceFromPool.toFixed()); ;
    return result;
  }
}
