import { Token } from '@uniswap/sdk';
import { Optional } from 'common/models/optional';
import { BigNumber } from 'ethers';
import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';

export namespace NumberUtils {
  export function formatFloatStandard(n: number | string | undefined, trunc: boolean = true): Optional<string> {
    if (n == null || isNaN(n)) {
      // TODO: Consider adding a loading for anything that calls this
      return '0';
    }
    let theNumber = +n;
    if (trunc) {
      theNumber = Math.trunc(theNumber);
    }
    return theNumber.toLocaleString(undefined, { maximumFractionDigits: 2 });
  }

  // converts BN string to a number
  export function BNtoNumberWithoutDecimals(val: string, decimals: number): number {
    if (val != null) {
      const digits = val.length;
      let tempVal = '';
      if (digits <= decimals) {
        tempVal = '0.';
        for (let i = 0; i < decimals - digits; i++) {
          tempVal = `${tempVal}0`;
        }
        tempVal = `${tempVal}${val}`;
      } else {
        for (let i = 0; i < digits - decimals; i++) {
          tempVal = `${tempVal}${val[i]}`;
        }
        tempVal = `${tempVal}.`;
        for (let i = digits - decimals; i < digits; i++) {
          tempVal = `${tempVal}${val[i]}`;
        }
      }
      return Number(tempVal);
    }
    return 0;
  }

  // converts BN to a user input string with a decimal point
  export function BNtoStringWithoutDecimals(value: BigNumber, decimals: number): string {
    if (value != null && value.gt(0)) {
      const val = value.toString();
      const digits = val.length;
      let tempVal = '';
      if (digits <= decimals) {
        tempVal = '0.';
        for (let i = 0; i < decimals - digits; i++) {
          tempVal = `${tempVal}0`;
        }
        tempVal = `${tempVal}${val}`;
      } else {
        for (let i = 0; i < digits - decimals; i++) {
          tempVal = `${tempVal}${val[i]}`;
        }
        tempVal = `${tempVal}.`;
        for (let i = digits - decimals; i < digits; i++) {
          tempVal = `${tempVal}${val[i]}`;
        }
      }
      tempVal = tempVal.replace(/0+$/, '');
      tempVal = tempVal.replace(/\.$/, '');
      if (tempVal === '') {
        tempVal = '0';
      }
      return tempVal;
    }
    return '0';
  }

  export function formatBigNumber(value: BigNumber, token: Token | null | undefined, decimals?: number): number {
    if (value == null) {
      throw new Error('Cannot format a null or undefined value');
    }
    if (token == null) {
      throw new Error('Cannot format a BigNumber without a Token');
    }
    if (token.decimals == null) {
      throw new Error('Cannot format a BigNumber without a Token decimals configured');
    }
    const decimalOverride = decimals && isNumber(decimals) ? decimals : token.decimals;
    const n = BNtoNumberWithoutDecimals(value.toString(), decimalOverride);
    return n;
  }

  // converts user input amount (with decimal point) to BN string with specified decimals
  export function stringToBNString(val: string, decimals: number): string {
    if (val != null) {
      const dotIndex = val.indexOf('.');
      let numDecimals = 0;
      if (dotIndex >= 0) {
        numDecimals = val.length - dotIndex - 1;
      }
      let tempVal = val.replace('.', '');
      if (numDecimals > decimals) {
        tempVal = tempVal.substring(0, tempVal.length - (numDecimals - decimals));
      }
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < decimals - numDecimals; i++) {
        tempVal = `${tempVal}0`;
      }
      tempVal = tempVal.replace(/^0+/, '');
      if (tempVal.length === 0) {
        tempVal = '0';
      }
      return tempVal;
    }
    return '0';
  }

  // converts user input amount (with decimal point) to BN with specified decimals
  export function stringToBN(val: string, decimals: number): BigNumber {
    try {
      return BigNumber.from(stringToBNString(val, decimals));
    } catch (error) {
      return BigNumber.from(0);
    }
  }

  export function addDecimalPointToString(val: string, decimals: number): string {
    let tempVal = val;
    while (tempVal.length <= decimals) {
      tempVal = `0${tempVal}`;
    }
    tempVal = `${tempVal.substring(0, tempVal.length - decimals)}.${tempVal.substring(tempVal.length - decimals)}`;
    tempVal = tempVal.replace(/0+$/, '');
    if (tempVal[tempVal.length - 1] === '.') {
      tempVal = tempVal.substring(0, tempVal.length - 1);
    }
    return tempVal;
  }

  // do not use this function on user input strings, use stringToBNString instead
  // ok to use it for values calculated (for instance for required amounts validation)
  export function getBNString18(val: number) : string {
    if (val >= 100000) {
      return `${Math.floor(val * 10 ** 9)}000000000`;
    // eslint-disable-next-line no-else-return
    } else if (val >= 1000) {
      return `${Math.floor(val * 10 ** 12)}000000`;
    }
    return `${Math.floor(val * 10 ** 18)}`;
  }

  // do not use this function on user input strings, use stringToBNString instead
  // ok to use it for values calculated (for instance for required amounts validation)
  export function getBNString9(val: number) : string {
    return `${Math.floor(val * 10 ** 9)}`;
  }

  export function threeDecimals(n) {
    const res = n.toFixed(20).match(/^-?\d*\.?0*\d{0,3}/);
    if (res && res[0]) {
      return Number(res[0]).toString();
    }
    return Number(n).toString();
  }

  export function significantCeil(val: string, decimals: number) {
    const exactValue = Number(val) / 10 ** decimals;
    let res = Math.ceil(exactValue * 1000) / 1000;
    if (exactValue > 1) {
      res = Math.ceil(exactValue * 1000) / 1000;
    } else if (exactValue > 0.1) {
      res = Math.ceil(exactValue * 10000) / 10000;
    } else if (exactValue > 0.01) {
      res = Math.ceil(exactValue * 100000) / 100000;
    } else if (exactValue > 0.001) {
      res = Math.ceil(exactValue * 1000000) / 1000000;
    } else if (exactValue > 0.0001) {
      res = Math.ceil(exactValue * 10000000) / 10000000;
    } else if (exactValue > 0.00001) {
      res = Math.ceil(exactValue * 100000000) / 100000000;
    }
    return res;
  }

  export function msToTime(s: number): string {
    const ms = s % 1000;
    s = (s - ms) / 1000;
    const secs = s % 60;
    s = (s - secs) / 60;
    const mins = s % 60;
    const hrs = (s - mins) / 60;

    return `${hrs}h : ${mins}m`;
  }
}
